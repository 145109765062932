import firebase from "firebase";
import useSWR from "swr";

export const useGetStatusChangedEventIds = () => {
  const apiKey = "fetchStatusChangedEventList";
  const { data, error, isLoading, mutate } = useSWR(apiKey, async () => {
    return await fetchStatusChangedEventIds();
  });

  const fetchStatusChangedEventIds = async (): Promise<string[]> => {
    const snapshot = await firebase
      .firestore()
      .collection("news")
      .doc("topInfo")
      .get();
    const data = snapshot.data();
    return data?.statusChangeEventIds ?? [];
  };

  return {
    data: data ?? [],
    error,
    isLoading,
    mutate,
  };
};
