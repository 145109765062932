/** @jsxRuntime classic /
/** @jsx jsx */
import { css, jsx } from "@emotion/core";
import styled from "@emotion/styled";
import React, { Component, useEffect } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Redirect } from "react-router";
import { push } from "connected-react-router";
import { Dispatch } from "redux";
import firebase from "firebase/app";
import "firebase/firestore";

import type { CVSCode } from "@spwn/types/gmo";
import { Place, TicketConfig } from "@spwn/types/firebase/firestore";
import type { TicketData } from "@spwn/types/firebase/database";

import appConfig from "constants/appConfig";
import {
  cvsInfo,
  N_PURCHASABLE_TICKETS_WITH_SINGLE_CODE,
} from "constants/purchase";
import { ArAppRequired } from "components/arApp/ArAppRequired";
import BorderLoader from "components/atoms/loader/BorderLoader";
import { LoaderLayout } from "components/atoms/loader/LoaderLayout";
import { BreadcrumbArea, GoExternalSite } from "components/common/Link";
import { EventTicketHeader } from "components/molecules/EventTicketHeader";
import { DocomoAccountCaution } from "components/molecules/DocomoAccountCaution";
import { SubContents } from "components/templates/SubContents";
import { useGetLotteryAppMap } from "features/accounts/useGetLotteryAppMap";
import { i18nextT } from "hooks/i18n/i18n";
import { useGetPlaceList } from "hooks/event/useGetPlaceList";
import {
  PaymentMethodForLottery,
  CardInfo,
  purchaseActions,
} from "modules/purchase";
import { authActions } from "modules/auth";
import { RedirectState } from "modules/router";
import { eventActions, UserArAppInfo } from "modules/event";
import { ticketActions } from "modules/ticket";
import { cartActions, ReqAddToCartData } from "modules/cart";
import { modalActions, ReqToggleError, ActionData } from "modules/modal";
import { Store } from "store";
import { fontDinMixin, ContentsLayout } from "styles";
import { TextLink } from "ui/TextLink";
import {
  getEventIdFromHref,
  createSet,
  convertMapToValues,
  getNowTimestamp,
  EventInfo,
  createListSetOfField,
  convertPlacesArrayToDict,
} from "utility";
import {
  fetchFbToken,
  fetchFirestoreCollectionBySnapshot,
} from "utility/firebase";
import { changeStyleWithHosting, isDocomoDomain } from "utility/hosting";
import select_arrow from "designs/images/select_pulldown.png";
import EventTicketSelectPlace from "./eventTicket/EventTicketSelectPlace";
import EventTicketSelectNum from "./eventTicket/EventTicketSelectNum";
import EventTicketSelectSubClass from "./eventTicket/EventTicketSelectSubClass";
import EventTicketActionButton from "./eventTicket/EventTicketActionButton";

export const EventTicket: React.FC = () => {
  const dispatch = useDispatch();

  const displayEvent = useSelector((state: Store) => state.event.displayEvent);
  const tickets = useSelector(
    (state: Store) => state.ticket.eventTicketData.ticketData
  );

  // イベントチケットを取得
  useEffect(() => {
    if (displayEvent._id) {
      dispatch(ticketActions.fetchEventTickets.started(displayEvent._id));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [displayEvent._id]);

  const ticketPlaceCodes = createSet(
    convertMapToValues(tickets).map((el) => el.place)
  );

  const { data: places, isLoading: isPlaceLoading } =
    useGetPlaceList(ticketPlaceCodes);

  const placeMap = places ? convertPlacesArrayToDict(places) : {};

  // 抽選申込中のチケットを取得
  const { lotteryAppMap, isLoading: isLotteryAppMapLoading } =
    useGetLotteryAppMap({
      eventIds: [displayEvent._id ?? ""],
      enabled: !!displayEvent._id,
    });

  // 抽選チケットは申し込み済み
  const isAlreadyLotteryApplied =
    !!displayEvent._id &&
    (lotteryAppMap?.[displayEvent._id]?.isActive ?? false);

  const isLoading = isPlaceLoading || isLotteryAppMapLoading;

  return isLoading ? (
    <LoaderLayout />
  ) : (
    <EventTicketLogicConnected
      event={displayEvent}
      placeMap={placeMap}
      tickets={tickets}
      isAlreadyLotteryApplied={isAlreadyLotteryApplied}
    />
  );
};

type Props = ValueProps & FuncProps & AttrProps;

interface AttrProps {
  event: EventInfo;
  placeMap: Record<string, Place>;
  tickets: { tid?: TicketData };
  isAlreadyLotteryApplied: boolean;
}

interface ValueProps {
  user: firebase.User;
  pathname: string;

  ticketConfig: TicketConfig;
  cardInfoList: null | CardInfo[];
  isAdmin: boolean;
  userArAppInfo: UserArAppInfo;
}
interface FuncProps {
  // @ts-expect-error TS7006
  toggleNotice: (msgObj) => void;
  fetchEventTickets: (eventId: string) => void;
  fetchProductConfig: (eventId: string) => void;
  toggleError: (msgObj: ReqToggleError) => void;
  addToCart: (payload: ReqAddToCartData) => void;
  applyTicketLottery: (payload: unknown) => void;
  fetchCardInfo: () => void;
  // @ts-expect-error TS7051
  addLoginAction: (any) => void;
  // @ts-expect-error TS7051
  fetchEventTicketData: (any) => void;
  openMyCart: () => void;
  confirmAccountLottery: () => void;
  fetchUserArAppInfo: () => void;
  clearUserArAppInfo: () => void;
  toggleActionModal: (payload: ActionData) => void;
  changeRoute: (path: string) => void;
}

interface States {
  eventId: string;
  products: Product[]; // カートに追加するもの
  openAppForm: boolean;
  hasApplied: boolean;
  isOpenPurchaseConfirm: boolean;
  selectedPlaceCode: null | string;
  selectedClassGroupId: null | string;
  selectedTicketNum: number;
  paymentMethod: PaymentMethodForLottery;
  fanClubId: "" | string;
  verificationCodes: string[];
  cvsParams: CVSParams;
  geoError: boolean;
  geoFetch: boolean;
  applicationState: ApplicationState;
  lotteryCart: { [key: string]: number };
  isRegisteredCard: null | boolean;
  selectedCardIdx: number;
  checkArAppRequired: boolean;
  optionalInputData: {
    [key: string]: {
      id: string;
      isRequired: boolean;
      caption: string;
      inputText: string;
      priority: number;
    };
  };
  linkageState: unknown[];
}

type CVSParams = {
  cvsCode: CVSCode;
  nameCVS: "" | string;
  kanaCVS: "" | string;
  telCVS: "" | string;
};

type ApplicationState = {
  orderType: PaymentMethodForLottery;
  cart: unknown;
  cvsParams: CVSParams | unknown;
  fanClubId: null | string;
  verificationCodes: string[];
};

/**
 * カートへ追加するためのリクエスト値となる商品
 */
type Product = {
  id: string;
  productType: "ticket";
  count: number;
  price: number;
  name: string;
};

const EventTicketContentsLayout = styled(ContentsLayout)`
  padding-bottom: 100px;
`;

const SettlementLayout = styled.div`
  @media screen and (min-width: 768px) {
    width: 740px;
    margin: 0 auto;
  }
  select {
    width: 100%;
  }
`;

SettlementLayout.displayName = "SettlementLayout";

const Heading = styled.h3`
  margin-bottom: 25px;
  display: flex;
  font-size: 18px;
  @media screen and (min-width: 768px) {
    margin-bottom: 40px;
    font-size: 25px;
  }
  span {
    ${fontDinMixin};
    display: block;
    margin-right: 5%;
    font-size: 35px;
    @media screen and (min-width: 768px) {
      margin-right: 20px;
      font-size: 43px;
    }
  }
`;

Heading.displayName = "Heading";

const Form01 = styled.div`
  margin-top: 40px;
  > p {
    margin-bottom: 15px;
  }
  > .not_registered {
    > p {
      font-size: 14px;
      line-height: 1.3em;
      letter-spacing: 1px;
      &:not(:last-child) {
        margin-bottom: 5px;
      }
      a {
        color: #11d4db;
        text-decoration: underline;
      }
    }
  }
`;

Form01.displayName = "Form01";

const Form02 = styled.div`
  margin-top: 20px;
  > p {
    margin-bottom: 15px;
  }
  > input {
    width: 100%;
    padding: 11px 10px;
    font-size: 16px;
    border: 1px solid #d4d4d4;
  }
`;

Form02.displayName = "Form02";

const Form03 = styled.div`
  @media screen and (min-width: 768px) {
    width: 740px;
    margin: 0 auto;
  }
  > input {
    width: 100%;
    padding: 11px 17px;
    font-size: 16px;
    border: 1px solid #d4d4d4;
  }
`;

Form03.displayName = "Form03";

const TicketDetails = styled.div`
  margin: 0 auto;
  background-color: #fff;
  @media screen and (min-width: 768px) {
    padding-top: 0;
  }
`;

TicketDetails.displayName = "TicketDetails";

class EventTicketLogic extends Component<Props, States> {
  _fanClubIdName = "会員ID";
  _verificationCodeName = "認証コード";
  // @ts-expect-error TS7006
  constructor(props) {
    super(props);
    const eventId = getEventIdFromHref(this.props.pathname, "events");

    this.state = {
      // @ts-expect-error TS2322
      eventId,
      products: [],
      openAppForm: false,
      hasApplied: false,
      isOpenPurchaseConfirm: false,
      selectedPlaceCode: null,
      selectedClassGroupId: null,
      geoError: false,
      geoFetch: false,
      selectedTicketNum: 0,
      paymentMethod: "MEM",
      fanClubId: "",
      verificationCodes: [""],
      cvsParams: {
        cvsCode: "10001",
        nameCVS: "",
        kanaCVS: "",
        telCVS: "",
      },
      applicationState: {
        orderType: "MEM",
        cart: {},
        cvsParams: {},
        fanClubId: null,
        // @ts-expect-error TS2322
        verificationCodes: null,
      },
      lotteryCart: {},
      isRegisteredCard: null,
      selectedCardIdx: -1,
      checkArAppRequired: false,
      // @ts-expect-error TS2322
      optionalInputData: null,
      isFetchingWaitingTicketLotteryList: false,
      linkageState: [],
    };
  }

  componentDidMount(): void {
    this.fetchDocomoAccountLinkingState();

    this.props.fetchProductConfig(this.state.eventId);
    this.props.fetchCardInfo();
    this.props.addLoginAction({
      action: purchaseActions.fetchCardInfo.started,
      args: null,
    });
    const payload = { eid: this.state.eventId };
    this.props.fetchEventTicketData(payload);
    this.props.addLoginAction({
      action: purchaseActions.fetchCardInfo.started,
      args: payload,
    });
    this.check_geo(this.state.eventId);

    // check if user already applied lottery
    if (this.props.isAlreadyLotteryApplied) {
      this.confirmApplyLottery();
    }
  }

  componentDidUpdate(_prevProps: Props) {
    if (
      !this.state.checkArAppRequired &&
      this.props.event &&
      this.props.event.isRequiredArApp &&
      !this.props.userArAppInfo
    ) {
      // fetch once
      this.props.fetchUserArAppInfo();
      this.setState({ checkArAppRequired: true });
    }
    // initialize optional input field
    if (
      this.props.event &&
      this.props.event.isRequiredTicketOptionalInputField &&
      !this.state.optionalInputData
    ) {
      let initialInputData: States["optionalInputData"] = {};
      // @ts-expect-error TS2769
      Object.keys(this.props.event.ticketOptionalInputData).forEach((key) => {
        // @ts-expect-error TS2532
        const inputData = this.props.event.ticketOptionalInputData[key];
        initialInputData = {
          ...initialInputData,
          [key]: {
            id: key,
            // @ts-expect-error TS18048
            isRequired: inputData.isRequired,
            // @ts-expect-error TS18048
            caption: inputData.caption,
            inputText: "",
            // @ts-expect-error TS18048
            priority: inputData.priority,
          },
        };
      });
      this.setState({
        optionalInputData: initialInputData,
      });
    }
  }

  componentWillUnmount() {
    this.props.clearUserArAppInfo();
  }

  static getDerivedStateFromProps(props: Props, state: States) {
    let { isRegisteredCard, selectedPlaceCode } = state;

    if (props.cardInfoList !== null) {
      isRegisteredCard = props.cardInfoList.length !== 0;
    }
    // state.eventId と redux stateのeventが変わっているか
    const tickets = convertMapToValues(props.tickets);
    if (
      tickets.filter((el) => el.eid === state.eventId).length !== 0 &&
      Object.keys(props.tickets).length !== 0 &&
      !!props.placeMap &&
      state.selectedPlaceCode === null
    ) {
      const sortTickets = tickets
        .filter((el) => el.display)
        .sort((prev, cur) => prev.priority - cur.priority);
      // @ts-expect-error TS2532
      selectedPlaceCode = sortTickets[0].place;
      const isSelectPlaceLaterMode =
        // @ts-expect-error TS2532
        !!props.placeMap[selectedPlaceCode].selectablePlaces;
      // @ts-expect-error TS2322
      selectedPlaceCode = isSelectPlaceLaterMode
        ? // @ts-expect-error TS2532
          props.placeMap[selectedPlaceCode].selectablePlaces[0]
        : selectedPlaceCode;
    }
    return {
      isRegisteredCard,
      selectedPlaceCode,
    };
  }

  checkInputField = (): boolean => {
    const { event } = this.props;
    // 商品が選択されているか確認
    if (this.state.products.length === 0) {
      this.props.toggleNotice({
        msg: i18nextT("modal.eventTicket.ticketChoose"),
      });
      return false;
    }
    // その他入力欄があればチェック
    const nowTimestamp = getNowTimestamp();
    const isRequireIdFanClubId =
      (event.isPreSaleOpen &&
        !!event.preSaleOpenTime &&
        !!event.preSaleCloseTime &&
        event.preSaleOpenTime.seconds < nowTimestamp &&
        event.preSaleCloseTime.seconds > nowTimestamp) ||
      event.isFanClubIdRequired;
    const isRequireValidation = event.isVerificationCodeRequired;
    if (isRequireIdFanClubId && !this.state.fanClubId) {
      this.props.toggleError({
        msg: i18nextT("modal.eventTicket.noInput", {
          name: this._fanClubIdName,
        }),
      });
      return false;
    }
    if (isRequireValidation && !this.state.verificationCodes) {
      this.props.toggleError({
        msg: i18nextT("modal.eventTicket.noInput", {
          name: this._verificationCodeName,
        }),
      });
      return false;
    }
    // check if input to optional input field
    if (event.isRequiredTicketOptionalInputField) {
      for (const key of Object.keys(this.state.optionalInputData)) {
        const inputData = this.state.optionalInputData[key];
        // @ts-expect-error TS18048
        if (inputData.isRequired && !inputData.inputText) {
          this.props.toggleError({
            msg: i18nextT("modal.eventTicket.noInput", {
              // @ts-expect-error TS18048
              name: inputData.caption,
            }),
          });
          return false;
        }
      }
    }
    return true;
  };

  addToCart = () => {
    this.props.addToCart({
      body: {
        eventId: this.state.eventId,
        products: this.state.products,
        fanClubId: this.state.fanClubId,
        invitationCode: this.state.verificationCodes[0],
      },
      actionOnModal: this.props.openMyCart,
      // @ts-expect-error TS2322
      selectedPlace: this.state.selectedPlaceCode,
      // @ts-expect-error TS2322
      optionalInputData: this.state.optionalInputData,
    });
  };

  filterTicketByPlace = (code: string) => {
    this.setState({ selectedPlaceCode: code });
    // 選択しているチケットをクリア
    this.setState({ products: [] });
  };

  /**
   * チケットの個数を選択する
   * - カートへ追加する値の更新
   */
  // @ts-expect-error TS7006
  selectTicket = (e, ticketId: string) => {
    let newCart: Product[] = [];
    const selectedCount = e.currentTarget.value;
    // すでに追加されている対象以外を追加する
    const otherProducts = this.state.products.filter(
      (ticket) => ticket.id !== ticketId
    );
    if (otherProducts.length !== 0) {
      newCart = otherProducts;
    }
    // 対象を追加する
    if (selectedCount > 0) {
      const [addProduct] = convertMapToValues(this.props.tickets).filter(
        (ticket) => ticket.display && ticket._id === ticketId
      );
      newCart.push({
        // @ts-expect-error TS18048
        id: addProduct._id,
        productType: "ticket",
        count: selectedCount,
        // @ts-expect-error TS18048
        price: addProduct.price_jpy,
        // @ts-expect-error TS18048
        name: addProduct.name,
      });
    }
    this.setState({ products: newCart });
  };

  // @ts-expect-error TS7006
  displaySubClass = (value) => {
    const selectedTicketNum = Number(value.split("_")[0]);
    const [selectedClassGroupId] = value.split("_");
    if (selectedTicketNum === 0) {
      this.setState({
        selectedClassGroupId: null,
      });
      return;
    }
    this.setState({
      selectedClassGroupId,
      selectedTicketNum,
    });
  };

  // @ts-expect-error TS7006
  selectTicketWithSubClass = (value) => {
    // @ts-expect-error TS7034
    let newCart = [];
    const selectedCount = this.state.selectedTicketNum;
    const otherProducts = this.state.products.filter(
      (ticket) => ticket.id !== value
    );
    if (otherProducts.length !== 0) {
      newCart = otherProducts;
    }
    if (this.state.selectedTicketNum > 0) {
      const [addProduct] = convertMapToValues(this.props.tickets).filter(
        (ticket) => ticket.display && ticket._id === value
      );
      newCart.push({
        // @ts-expect-error TS18048
        id: addProduct._id,
        // @ts-expect-error TS18048
        productType: addProduct.productType,
        count: selectedCount,
      });
    }
    // @ts-expect-error TS7005
    this.setState({ products: newCart });
  };

  // @ts-expect-error TS7006
  handleSelectorChange = (e, ticket: TicketData) => {
    const select = e.currentTarget;
    const ticketId = select.options[select.selectedIndex].id;
    const { value } = select.options[select.selectedIndex];

    // HOTFIX class1のtotalチェック
    let isDisBuyable = false;
    for (const ticketId of Object.keys(this.state.lotteryCart)) {
      // @ts-expect-error TS7053
      const _ticket = this.props.tickets[ticketId];
      if (
        _ticket &&
        ticketId !== ticket._id &&
        _ticket.class === 1 &&
        ticket.class === 1
      ) {
        this.props.toggleError({
          msg: i18nextT("modal.eventTicket.limitTicket"),
        });
        isDisBuyable = true;
        break;
      }
    }
    if (isDisBuyable) {
      e.currentTarget.options[
        this.state.lotteryCart.hasOwnProperty(ticketId)
          ? Number(this.state.lotteryCart[ticketId])
          : 0
      ].selected = true;
      return;
    }

    const newCart = {
      ...this.state.lotteryCart,
      [ticketId]: Number(value),
    };
    if (value === 0) {
      // @ts-expect-error TS7053
      delete newCart[ticketId];
    }

    const newTotal = Object.keys(newCart).reduce((a, key) => {
      // @ts-expect-error TS7053
      return a + Number(newCart[key]);
    }, 0);

    // @ts-expect-error TS2532
    if (newTotal > this.props.ticketConfig.config.maxTicketPurchase) {
      this.props.toggleError({
        // msg:`最大1人${this.props.ticketConfig.config.maxTicketPurchase}枚までしか購入できません`
        msg: i18nextT("modal.eventTicket.limitTicket2", {
          // @ts-expect-error TS2532
          maxTicketPurchase: this.props.ticketConfig.config.maxTicketPurchase,
        }),
      });
      e.currentTarget.options[
        this.state.lotteryCart.hasOwnProperty(ticketId)
          ? Number(this.state.lotteryCart[ticketId])
          : 0
      ].selected = true;
      return;
    }

    const nRequiredCodes = Math.max(
      0,
      Math.ceil(
        // @ts-expect-error TS2345
        newTotal / N_PURCHASABLE_TICKETS_WITH_SINGLE_CODE(this.props.event._id)
      )
    );
    if (nRequiredCodes > this.state.verificationCodes.length) {
      const diff = nRequiredCodes - this.state.verificationCodes.length;
      this.setState({
        verificationCodes: this.state.verificationCodes.concat(
          Array(diff).fill("")
        ),
      });
    } else if (nRequiredCodes < this.state.verificationCodes.length) {
      this.setState({
        verificationCodes: this.state.verificationCodes.slice(
          0,
          nRequiredCodes
        ),
      });
    }
    if (nRequiredCodes === 0) {
      this.setState({ verificationCodes: [""] });
    }

    this.setState({
      lotteryCart: newCart,
    });
  };

  // @ts-expect-error TS7006
  handleChangePlace = (placeCode) => {
    this.setState({
      selectedPlaceCode: placeCode,
      lotteryCart: {},
    });
  };

  // @ts-expect-error TS7006
  handleUpdatePaymentMethod = (e) => {
    this.setState({
      selectedCardIdx: -1,
      paymentMethod: e.currentTarget.value,
    });
  };

  // @ts-expect-error TS7006
  handleUpdateClubId = (e) => {
    this.setState({ fanClubId: e.currentTarget.value });
  };

  // @ts-expect-error TS7006
  handleUpdateVerificationCode = (e, idx) => {
    const newVerificationCodes = [...this.state.verificationCodes];
    newVerificationCodes[idx] = e.currentTarget.value;
    this.setState({ verificationCodes: newVerificationCodes });
  };

  handleUpdateOptionalInputField = (
    // @ts-expect-error TS7006
    e,
    key: string,
    isRequired: boolean,
    caption: string,
    priority: number
  ) => {
    this.setState({
      optionalInputData: {
        ...this.state.optionalInputData,
        [key]: {
          id: key,
          isRequired,
          caption,
          priority,
          inputText: e.currentTarget.value,
        },
      },
    });
  };

  // @ts-expect-error TS7006
  handleUpdateParamsCVS = (e) => {
    this.setState({
      cvsParams: {
        ...this.state.cvsParams,
        [e.currentTarget.id]: e.currentTarget.value,
      },
    });
    // console.log(this.state.lotteryCart)
  };

  // @ts-expect-error TS7006
  handleCardInfoIndex = (e) => {
    this.setState({
      selectedCardIdx: Number(e.currentTarget.value),
    });
    // console.log(this.state.lotteryCart)
  };

  /**
   * if user already apply lottery, open confirm popup
   */
  confirmApplyLottery = () => {
    this.props.toggleActionModal({
      actionModalType: "confirmAction",
      caption: i18nextT("modal.eventTicket.application"),
      msg: i18nextT("modal.eventTicket.checkMsg", {
        verificationCodeName: this._verificationCodeName,
      }),
      btnMsg: i18nextT("modal.eventTicket.applicationCheck"),
      closeBtnMsg: i18nextT("modal.eventTicket.applicationUpdate"),
      // ここ結構気持ちわるいけどとりあえずメインで型を用意したかったので今後リファクタリング
      action: this.props.changeRoute as unknown as ActionData["action"],
      args: "/account/lottery" as unknown as ActionData["args"],
    });
  };

  // @ts-expect-error TS7006
  check_geo = async (eid) => {
    // const fbToken = await firebase.auth().currentUser.getIdToken(true);
    const fbToken = await fetchFbToken();
    // @ts-expect-error TS2769
    const response = await fetch(appConfig.CloudFunctions.checkGeo, {
      // const response = await fetch("http://www.spwn.jp:8000/get_streaming_key", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `'Bearer ${fbToken}`,
      },
      body: JSON.stringify({ eid }),
    });

    let json2 = null;
    try {
      const url = `${appConfig.backendApiUrl}/portal/python-functions/checkGeo`;
      const response2 = await fetch(url, {
        mode: "cors",
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${fbToken}`,
        },
        body: JSON.stringify({ eid }),
        credentials: "include",
      });

      json2 = await response2.json();

      console.debug("checkGeo");
      console.debug(json2);
    } catch (e) {
      console.error(e);
    }

    if (response.status === 200) {
      const json = await response.json();

      try {
        const url = `${appConfig.backendApiUrl}/portal/python-functions/diffLogger`;
        await fetch(url, {
          mode: "cors",
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${fbToken}`,
          },
          body: JSON.stringify({
            json,
            json2,
            // browserのcacheなどがあって、古いコードが走っている可能性があるので、versionを指定して、いつのコードが走っているかを明示する
            meta: { name: "checkGeo", msg: { eid }, version: "1" },
          }),
          credentials: "include",
        });
      } catch (e) {
        console.error(e);
      }

      // console.log("OK!!!!!!!!")
      // console.log(json)
      if (json.hasOwnProperty("isError") && json.isError) {
        this.setState({ geoError: true });
      }
      if (json) {
        this.setState({ geoFetch: true });
      }
    }
  };

  applyLottery = () => {
    const { event } = this.props;
    //無料抽選時は下の処理を動かさないで渡す 他のいい書き方ないか?
    if (event.lotteryTicketSellingMode === "free") {
      this.props.applyTicketLottery({
        eventId: this.state.eventId,
        cart: this.state.lotteryCart,
        orderType: "FREE",
        cvsParams: this.state.cvsParams,
        fanClubId: this.state.fanClubId,
        verificationCode: "",
        optionalInputData: this.state.optionalInputData,
        actionOnModal: this.props.confirmAccountLottery,
      });
      return;
    }

    if (
      this.state.lotteryCart === null ||
      Object.keys(this.state.lotteryCart).length === 0
    ) {
      this.props.toggleError({
        msg: i18nextT("modal.eventTicket.chooseGoodsMsg"),
      });
      return;
    }
    if (
      this.state.paymentMethod === "MEM" &&
      this.state.selectedCardIdx === -1
    ) {
      this.props.toggleError({
        msg: i18nextT("modal.eventTicket.chooseCardMsg"),
      });
      return;
    } else if (
      this.state.paymentMethod === "CVS" &&
      (this.state.cvsParams.nameCVS === "" ||
        this.state.cvsParams.kanaCVS === "" ||
        this.state.cvsParams.telCVS === "")
    ) {
      this.props.toggleError({ msg: i18nextT("modal.eventTicket.noInputMsg") });
      return;
    }
    // 先行販売中はコードが入力されているか確認
    if (
      (event.hasOwnProperty("isPreSaleOpen") &&
        event.hasOwnProperty("preSaleOpenTime") &&
        event.hasOwnProperty("preSaleCloseTime") &&
        event.isPreSaleOpen &&
        event.preSaleOpenTime.seconds * 1000 < Date.now() &&
        event.preSaleCloseTime.seconds * 1000 > Date.now()) ||
      event.isFanClubIdRequired
    ) {
      if (
        this.state.fanClubId === "" ||
        this.state.fanClubId === null ||
        this.state.fanClubId === undefined
      ) {
        this.props.toggleError({
          msg: i18nextT("modal.eventTicket.noInput", {
            name: this._fanClubIdName,
          }),
        });
        return;
      }
    }

    let [verificationCode] = this.state.verificationCodes;
    if (
      event.hasOwnProperty("isVerificationCodeRequired") &&
      event.hasOwnProperty("isMultiVerificationCodeMode") &&
      event.isVerificationCodeRequired &&
      event.isMultiVerificationCodeMode
    ) {
      verificationCode = this.state.verificationCodes.join(", ");
    }
    if (
      event.hasOwnProperty("isVerificationCodeRequired") &&
      event.isVerificationCodeRequired
    ) {
      if (
        verificationCode === "" ||
        this.state.verificationCodes === null ||
        this.state.verificationCodes === undefined
      ) {
        this.props.toggleError({
          msg: i18nextT("modal.eventTicket.noInput", {
            name: this._verificationCodeName,
          }),
        });
        return;
      }
    }

    const total = convertMapToValues(this.state.lotteryCart).reduce(
      (previousValue: number, currentValue: number) => {
        return previousValue + currentValue;
      },
      0
    );
    // @ts-expect-error TS2532
    if (total > this.state.lotteryCart.maxTicketPurchase) {
      // this.props.toggleError({msg: `1人あたり合計${this.props.ticketConfig.config.maxTicketPurchase}枚までお選びいただけます`});
      this.props.toggleError({
        msg: i18nextT("modal.eventTicket.noInput", {
          // @ts-expect-error TS2532
          name: this.props.ticketConfig.config.maxTicketPurchase,
        }),
      });
      return;
    }

    if (
      event.hasOwnProperty("isVerificationCodeRequired") &&
      event.hasOwnProperty("isMultiVerificationCodeMode") &&
      event.isVerificationCodeRequired &&
      event.isMultiVerificationCodeMode
    ) {
      const n_codes = this.state.verificationCodes.reduce((pre, val) => {
        const n = val === "" || val === undefined || val === null ? 0 : 1;
        return pre + n;
      }, 0);
      // @ts-expect-error TS2345
      if (n_codes * N_PURCHASABLE_TICKETS_WITH_SINGLE_CODE(event._id) < total) {
        // @ts-expect-error TS2345
        const n = N_PURCHASABLE_TICKETS_WITH_SINGLE_CODE(event._id);
        const nRequired = Math.ceil((total - n_codes * n) / n);
        this.props.toggleError({
          msg: i18nextT("modal.eventTicket.shortRequireMsg", {
            verificationCodeName: this._verificationCodeName,
            num: nRequired,
          }),
        });
        return;
      }
    }

    this.props.applyTicketLottery({
      eventId: this.state.eventId,
      cart: this.state.lotteryCart,
      orderType: this.state.paymentMethod,
      cvsParams: this.state.cvsParams,
      fanClubId: this.state.fanClubId,
      verificationCode,
      selectedCardIdx: Number(this.state.selectedCardIdx),
      optionalInputData: this.state.optionalInputData,
      actionOnModal: this.props.confirmAccountLottery,
    });
  };

  fetchDocomoAccountLinkingState = async () => {
    const snapshot = firebase
      .firestore()
      .collection(`openId/docomo/linkingStates`)
      .where("uid", "==", this.props.user.uid)
      .get();
    const linkageState = await fetchFirestoreCollectionBySnapshot(snapshot);
    this.setState({ linkageState });
  };

  render() {
    const classesOptionalInputStyles = optionalInputStyles();
    if (!this.props.event || !this.state.geoFetch) {
      return (
        <div id="contents-layout">
          <LoaderLayout />
        </div>
      );
    }
    if (
      this.props.event.hasOwnProperty("fanClubIdName") &&
      this.props.event.fanClubIdName !== null &&
      this.props.event.fanClubIdName !== undefined &&
      this.props.event.fanClubIdName !== ""
    ) {
      this._fanClubIdName = this.props.event.fanClubIdName;
    }
    if (
      this.props.event.hasOwnProperty("verificationCodeName") &&
      this.props.event.verificationCodeName !== null &&
      this.props.event.verificationCodeName !== undefined &&
      this.props.event.verificationCodeName !== ""
    ) {
      this._verificationCodeName = this.props.event.verificationCodeName;
    }
    // if(this.props.event.lotteryTicketSellingMode === 'free'){
    //   this.setState({paymentMethod : 'FREE'})
    // }
    const eventInfo = this.props.event;

    // if ticket purchase is external site, redirect its site
    if (eventInfo.ticketUrl) {
      return <GoExternalSite externalLink={eventInfo.ticketUrl} />;
    }

    if (
      Object.keys(this.props.tickets).length === 0 ||
      !this.props.ticketConfig ||
      !this.state.selectedPlaceCode ||
      (eventInfo.isRequiredArApp && !this.props.userArAppInfo)
    ) {
      return (
        <div id="contents-layout">
          <LoaderLayout />
        </div>
      );
    }

    // if event is required ar app and user don't have the app, add flow fo install
    if (
      eventInfo.isRequiredArApp &&
      this.props.userArAppInfo &&
      !this.props.userArAppInfo.hasArApp
    ) {
      return (
        <TicketDetails id="contents">
          <Breadcrumb eventInfo={eventInfo} />
          <EventTicketContentsLayout>
            <EventTicketHeader eventInfo={eventInfo} />
            <ArAppRequired eventId={eventInfo.eventId} />
          </EventTicketContentsLayout>
        </TicketDetails>
      );
    }

    if (!this.props.isAdmin && eventInfo.eventSaleStatus !== "ON_SALE") {
      const redirectState: RedirectState = {
        openNoticeNotOnSale: true,
        eventSaleStatus: eventInfo.eventSaleStatus,
      };
      return (
        <Redirect
          to={{
            pathname: `/events/${eventInfo.eventId}`,
            state: redirectState,
          }}
        />
      );
    }

    // priorityでsort
    const nowTimestamp = getNowTimestamp();
    const sortTickets = convertMapToValues(this.props.tickets)
      .filter(
        (el) =>
          (el.display &&
            el.saleType !== "liveCommerceOnly" &&
            el.releaseDateTime / 1000 <= nowTimestamp &&
            nowTimestamp < el.closeDateTime / 1000) ||
          this.props.isAdmin
      )
      .sort((prev, cur) => prev.priority - cur.priority);
    // 選択している会場のみのチケット表示

    // sort place
    const checkPlaceCodes = createSet(sortTickets.map((el) => el.place));
    const places: Place[] = [];
    const isSelectPlaceLaterMode =
      checkPlaceCodes.length === 1 &&
      // @ts-expect-error TS2538
      !!this.props.placeMap[checkPlaceCodes[0]].selectablePlaces;
    const placeCodes = isSelectPlaceLaterMode
      ? // @ts-expect-error TS2538
        this.props.placeMap[checkPlaceCodes[0]].selectablePlaces
      : checkPlaceCodes;

    for (const placeCode of placeCodes) {
      const place = this.props.placeMap[placeCode];
      if (place) {
        places.push(place);
      }
    }

    let displayTickets: TicketData[];
    displayTickets = isSelectPlaceLaterMode
      ? sortTickets
      : sortTickets.filter((el) => el.place === this.state.selectedPlaceCode);

    if (this.props.ticketConfig.hasSubClass) {
      displayTickets = createListSetOfField<TicketData>(
        displayTickets,
        "classGroupId"
      );
    }

    const displaySubClassTickets =
      this.props.ticketConfig.hasSubClass && this.state.selectedClassGroupId
        ? sortTickets.filter(
            (el) => el.classGroupId === this.state.selectedClassGroupId
          )
        : [];

    const disenableAddToCart = this.state.products.length === 0;

    if (this.state.geoError) {
      return (
        <TicketDetails id="contents">
          <SubContents>
            <Breadcrumb eventInfo={eventInfo} />
            <EventTicketContentsLayout>
              <EventTicketHeader eventInfo={eventInfo} />
              <p css={classesOptionalInputStyles.IPblockText}>
                {i18nextT("ticket.IPBlock.p1")}
              </p>
            </EventTicketContentsLayout>
          </SubContents>
        </TicketDetails>
      );
    }

    const classes = styles();
    if (isDocomoDomain() && this.state.linkageState.length === 0) {
      return (
        <SubContents>
          <Breadcrumb eventInfo={eventInfo} />

          <div css={classes.root}>
            <EventTicketHeader eventInfo={eventInfo} />
            <DocomoAccountCaution />
          </div>
        </SubContents>
      );
    }

    return (
      <TicketDetails id="contents">
        <Breadcrumb eventInfo={eventInfo} />

        <EventTicketContentsLayout>
          <EventTicketHeader eventInfo={eventInfo} />
          <div css={classesOptionalInputStyles.selectList}>
            <EventTicketSelectPlace
              places={places}
              selectedPlaceCode={this.state.selectedPlaceCode}
              filterTicketByPlace={this.filterTicketByPlace}
              handleChangePlace={this.handleChangePlace}
            />

            <EventTicketSelectNum
              displayTickets={displayTickets}
              selectedPlaceCode={this.state.selectedPlaceCode}
              placeMap={this.props.placeMap}
              ticketConfig={this.props.ticketConfig}
              event={this.props.event}
              displaySubClass={this.displaySubClass}
              selectTicket={this.selectTicket}
              handleSelectorChange={this.handleSelectorChange}
            />

            <EventTicketSelectSubClass
              displaySubClassTickets={displaySubClassTickets}
              ticketConfig={this.props.ticketConfig}
              // @ts-expect-error TS2322
              selectedClassGroupId={this.state.selectedClassGroupId}
              selectTicketWithSubClass={this.selectTicketWithSubClass}
            />
            {this.inputFieldRenderSelector(this.props.event)}
          </div>

          <EventTicketActionButton
            event={this.props.event}
            displayTickets={displayTickets}
            disenableAddToCart={disenableAddToCart}
            applyLottery={this.applyLottery}
            checkInputField={this.checkInputField}
            addToCart={this.addToCart}
          />
        </EventTicketContentsLayout>
      </TicketDetails>
    );
  }

  inputFieldRenderSelector = (event: Partial<EventInfo>) => {
    let captionNumber = 3;
    const nowTimestamp = getNowTimestamp();
    const lotteryMode = event.lotteryTicketSellingMode;
    const isRequirePaymentMethod = event.isLotteryTicketSellingMode;
    const isRequireId =
      (event.isPreSaleOpen &&
        !!event.preSaleOpenTime &&
        !!event.preSaleCloseTime &&
        event.preSaleOpenTime.seconds < nowTimestamp &&
        event.preSaleCloseTime.seconds > nowTimestamp) ||
      event.isFanClubIdRequired;
    const isRequireValidation = event.isVerificationCodeRequired;
    return (
      <React.Fragment>
        {isRequirePaymentMethod &&
        (lotteryMode === "normal" || lotteryMode === undefined)
          ? this.paymentMethodRender(captionNumber++)
          : null}
        {isRequireId ? this.fanClubIdAreaRender(captionNumber++) : null}
        {isRequireValidation
          ? this.verificationCodeAreaRender(captionNumber++)
          : null}
        {event.isRequiredTicketOptionalInputField
          ? this.optionalInputAreaRender(
              captionNumber++,
              event.ticketOptionalInputData
            )
          : null}
      </React.Fragment>
    );
  };

  paymentMethodRender = (captionNumber: number) => {
    const cvsInputArea = (
      <React.Fragment>
        <Form01>
          <p>{i18nextT("modal.eventTicket.useConvenience")}</p>
          <select
            name="method"
            id="cvsCode"
            onChange={this.handleUpdateParamsCVS}
          >
            {/*<option value="">利用するコンビニを選択してください</option>*/}
            {Object.keys(cvsInfo()).map((code, i) => {
              return (
                <option key={i} value={code}>
                  {/*
                   // @ts-expect-error TS7053 */}
                  {cvsInfo()[code]}
                </option>
              );
            })}
          </select>
        </Form01>
        <Form02>
          <p>{i18nextT("modal.eventTicket.name")}</p>
          <input
            type="text"
            maxLength={39}
            onChange={this.handleUpdateParamsCVS}
            id="nameCVS"
            placeholder={i18nextT("modal.eventTicket.name")}
            name="name"
          />
        </Form02>
        <Form02>
          <p>{i18nextT("modal.eventTicket.furigana")}</p>
          <input
            type="text"
            maxLength={39}
            onChange={this.handleUpdateParamsCVS}
            id="kanaCVS"
            placeholder={i18nextT("modal.eventTicket.furigana")}
            name="name_f"
          />
        </Form02>
        <Form02>
          <p>{i18nextT("modal.eventTicket.phoneNumber")}</p>
          <input
            type="text"
            maxLength={13}
            onChange={this.handleUpdateParamsCVS}
            id="telCVS"
            placeholder={i18nextT("modal.eventTicket.phoneNumber")}
            name="tel"
          />
        </Form02>
      </React.Fragment>
    );

    const creditInputArea =
      this.state.isRegisteredCard === null ? (
        <BorderLoader />
      ) : (
        <Form01>
          <p>{i18nextT("modal.eventTicket.useCredit")}</p>
          {this.state.isRegisteredCard ? (
            <React.Fragment>
              <select onChange={this.handleCardInfoIndex}>
                <option key="registeredCard" value={-1}>
                  {i18nextT("modal.eventTicket.chooseCard")}
                </option>
                {/*
                 // @ts-expect-error TS2531 */}
                {this.props.cardInfoList.map((cardInfo, i) => {
                  return (
                    <option key={i} value={i}>
                      {cardInfo.cardNumber}
                    </option>
                  );
                })}
              </select>
            </React.Fragment>
          ) : (
            <div className="not_registered">
              <p>{i18nextT("modal.eventTicket.noCard")}</p>
              <p>
                <Link to="/account/credit">
                  {i18nextT("modal.eventTicket.this")}
                </Link>
                {i18nextT("modal.eventTicket.noteCard")}
              </p>
            </div>
          )}
        </Form01>
      );

    return (
      <React.Fragment>
        <div>
          <Heading>
            <span>{captionNumber}</span>
            {i18nextT("modal.eventTicket.chooseSettlement")}
          </Heading>
          <SettlementLayout>
            <select
              onChange={this.handleUpdatePaymentMethod}
              defaultValue="MEM"
            >
              <option value="MEM">
                {i18nextT("modal.eventTicket.paymentCard")}
              </option>
              <option value="CVS">
                {i18nextT("modal.eventTicket.paymentCVS")}
              </option>
            </select>
            {this.state.paymentMethod === "CVS"
              ? cvsInputArea
              : creditInputArea}
          </SettlementLayout>
        </div>
      </React.Fragment>
    );
  };

  fanClubIdAreaRender = (captionNumber: number) => {
    return (
      <div>
        <Heading>
          <span>{captionNumber}</span>
          {this._fanClubIdName}を入力してください
        </Heading>
        <Form03>
          <input
            type="text"
            onChange={this.handleUpdateClubId}
            id="clubId"
            name="fanclub_id"
          />
        </Form03>
      </div>
    );
  };

  verificationCodeAreaRender = (captionNumber: number) => {
    if (
      this.props.event.hasOwnProperty("isMultiVerificationCodeMode") &&
      this.props.event.isMultiVerificationCodeMode
    ) {
      const total = Object.keys(this.state.lotteryCart).reduce((a, key) => {
        return a + Number(this.state.lotteryCart[key]);
      }, 0);
      const nRequiredCodes = Math.max(
        Math.ceil(
          // @ts-expect-error TS2345
          total / N_PURCHASABLE_TICKETS_WITH_SINGLE_CODE(this.props.event._id)
        ),
        1
      );
      const input_fields = [...Array(nRequiredCodes)].map((key, idx) => {
        return (
          <Form03
            key={`verification_code_input_area_${idx}_`}
            style={{ paddingTop: "5px" }}
          >
            <input
              type="text"
              id="verificationCode"
              onChange={(e) => {
                this.handleUpdateVerificationCode(e, idx);
              }}
              value={this.state.verificationCodes[idx]}
              name="auth_id"
            />
          </Form03>
        );
      });
      return (
        <div className="verificationCode">
          <Heading>
            <span>{captionNumber}</span>
            {this._verificationCodeName}を入力してください
          </Heading>
          <SettlementLayout>
            {input_fields}
            <p>
              {this._verificationCodeName}が{input_fields.length}つ必要です
            </p>
            {this.props.event.isMultiVerificationCodeMode ? (
              <div className="verificationCautionText">
                <p>{`1つのチケットに対して、複数口数を応募される方はカンマ区切りで複数の${this._verificationCodeName}を入力してください。`}</p>
                <p>{`1枚のチケットに対して、3つの${this._verificationCodeName}を使って抽選口数を増やしたい場合は、入力欄に下記の複数の${this._verificationCodeName}を入力しご応募ください。`}</p>
                <p>{`（例）[AAAAA,BBBBB,CCCCCC]`}</p>
                <p>{`※ 複数の${this._verificationCodeName}を複数回、応募する必要はございません。`}</p>
                <p>{`※ 別の画面に遷移したあと、再度同じ公演のチケットを応募した場合は、応募内容が上書きされてしまいます。`}</p>
                <p>
                  {
                    "※ 最終的な応募内容につきましては、『 ホーム > アカウント情報 > "
                  }
                  <TextLink href={"/account/lottery"}>
                    抽選申込中のチケット一覧
                  </TextLink>
                  {` 』のチケット一覧に表示されているチケット枚数、及び「利用した${this._verificationCodeName}」数をご確認ください。`}
                </p>
              </div>
            ) : null}
          </SettlementLayout>
        </div>
      );
    } else {
      return (
        <div>
          <Heading>
            <span>{captionNumber}</span>
            {this._verificationCodeName}を入力してください
          </Heading>
          <Form03>
            <input
              type="text"
              id="verificationCode"
              onChange={(e) => {
                this.handleUpdateVerificationCode(e, 0);
              }}
              value={this.state.verificationCodes[0]}
              name="auth_id"
            />
          </Form03>
        </div>
      );
    }
  };

  optionalInputAreaRender = (
    captionNumber: number,
    // @ts-expect-error TS7006
    ticketOptionalInputData
  ) => {
    const classesOptionalInputStyles = optionalInputStyles();
    const sortFields = Object.keys(ticketOptionalInputData).sort((a, b) => {
      return (
        ticketOptionalInputData[a].priority -
        ticketOptionalInputData[b].priority
      );
    });
    return sortFields.map((key, i) => {
      const inputData = ticketOptionalInputData[key];
      const caption = inputData.isRequired
        ? inputData.caption
        : `【任意】${inputData.caption}`;
      return (
        <div css={classesOptionalInputStyles.root} key={i}>
          <h3>
            <span>{captionNumber + i}</span>
            {caption}
          </h3>
          <div css={classesOptionalInputStyles.form}>
            {inputData.inputInfo && inputData.inputInfo.type === "select" && (
              <select
                onChange={(e) =>
                  this.handleUpdateOptionalInputField(
                    e,
                    key,
                    inputData.isRequired,
                    inputData.caption,
                    inputData.priority
                  )
                }
              >
                <option value={""}>-----</option>
                {/*
                 // @ts-expect-error TS7006 */}
                {inputData.inputInfo.options.map((item, index) => {
                  return (
                    <option value={item} key={index}>
                      {item}
                    </option>
                  );
                })}
              </select>
            )}

            {(!inputData.inputInfo || inputData.inputInfo.type === "text") && (
              <input
                type="text"
                onChange={(e) =>
                  this.handleUpdateOptionalInputField(
                    e,
                    key,
                    inputData.isRequired,
                    inputData.caption,
                    inputData.priority
                  )
                }
              />
            )}
          </div>
        </div>
      );
    });
  };
}

const mapStateToProps = (state: Store) => {
  const props: ValueProps = {
    user: state.auth.user,
    pathname: state.router.location.pathname,
    ticketConfig: state.ticket.config.ticketConfig,
    cardInfoList: state.purchase.cardInfoList,
    isAdmin: state.admin.isAdmin,
    userArAppInfo: state.event.userArAppInfo,
  };
  return props;
};

const mapDispatchToProps = (dispatch: Dispatch) => {
  const func: FuncProps = {
    toggleNotice: (msgObj) => {
      dispatch(modalActions.toggleNotice(msgObj));
    },
    fetchEventTickets: (eventId: string) => {
      dispatch(ticketActions.fetchEventTickets.started({ eventId }));
    },
    fetchProductConfig: (eventId: string) => {
      dispatch(ticketActions.fetchProductConfig.started({ eventId }));
    },
    toggleError: (msgObj) => {
      dispatch(modalActions.toggleError(msgObj));
    },
    addToCart: (payload) => {
      dispatch(cartActions.addToCart.started(payload));
    },
    applyTicketLottery: (payload) => {
      dispatch(ticketActions.applyTicketLottery.started(payload));
    },
    fetchCardInfo: () => {
      dispatch(purchaseActions.fetchCardInfo.started({}));
    },
    addLoginAction: (action) => {
      dispatch(authActions.addLoginAction(action));
    },
    fetchEventTicketData: (action) => {
      dispatch(ticketActions.fetchEventTicketData.started(action));
    },
    openMyCart: () => {
      dispatch(cartActions.toggleMyCart({ isOpen: true }));
    },
    confirmAccountLottery: () => {
      dispatch(push("/account/lottery"));
    },
    fetchUserArAppInfo: () => {
      dispatch(eventActions.fetchUserArAppInfo.started({}));
    },
    clearUserArAppInfo: () => {
      dispatch(eventActions.clearStateByKey("userArAppInfo"));
    },
    toggleActionModal: (payload) => {
      dispatch(modalActions.toggleActionModal(payload));
    },
    changeRoute: (link) => {
      dispatch(push(link));
    },
  };
  return func;
};

const EventTicketLogicConnected = connect(
  mapStateToProps,
  mapDispatchToProps
)(EventTicketLogic);

const Breadcrumb = (props: { eventInfo: EventInfo }) => {
  const { eventInfo } = props;
  const breadcrumbText =
    changeStyleWithHosting().commonSettings.menu.event.text;
  return (
    <BreadcrumbArea
      // @ts-expect-error TS2322
      paths={[
        ["/", "ホーム"],
        ["/events", breadcrumbText],
        ["/events/" + eventInfo.eventId, eventInfo.eventTitle],
        [null, i18nextT("common.routes.ticketPurchase")],
      ]}
    />
  );
};

const styles = () => {
  return {
    root: css`
      width: 95%;
      max-width: 980px;
      padding: 8px 0 72px;
      margin: 0 auto;
      @media screen and (min-width: 768px) {
        padding: 8px 0;
      }
    `,
  };
};

const optionalInputStyles = () => {
  return {
    root: css`
      margin-top: 60px;
      @media screen and (min-width: 768px) {
        margin-top: 100px;
      }
      h3 {
        display: flex;
        margin-bottom: 25px;
        font-size: 18px;
        @media screen and (min-width: 768px) {
          margin-bottom: 40px;
          font-size: 25px;
        }
        span {
          display: block;
          margin-right: 5%;
          font-family: "din-condensed", sans-serif;
          font-weight: 400;
          font-style: normal;
          font-size: 35px;
          @media screen and (min-width: 768px) {
            margin-right: 20px;
            font-size: 43px;
          }
        }
      }
    `,
    form: css`
      width: 100%;
      max-width: 740px;
      margin: 0 auto;
      input[type="text"] {
        width: 100%;
        padding: 11px 17px;
        font-size: 16px;
        border: 1px solid #d4d4d4;
      }
      select {
        width: auto;
        max-width: 100%;
        padding: 8px 45px 8px 17px;
        font-size: 16px;
        font-weight: bold;
        border: 1px solid #dedede;
        background: url(${select_arrow}) right 50% no-repeat;
        background-size: 29px, 100%;
        border-radius: 0;
        appearance: none;
      }
    `,
    selectList: css`
      margin-bottom: 40px;
    `,
    IPblockText: css`
      text-align: center;
      font-size: 16px;
      font-weight: bold;
      letter-spacing: 1px;
      line-height: 1.5em;
    `,
  };
};
